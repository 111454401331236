import Navbar from "./components/Navbar/navbar";
import Hero from "./components/Hero/Hero";
import Demo from "./components/Demo/Demo";
import Footer from "./components/Footer/Footer";
import Experties from "./components/Experties/Experties";
import Work from "./components/Work/Work";
import css from "./styles/App.module.scss";
import People from "./components/People/People";
function App() {
  return (
    <div className={`bg-primary ${css.container}`}>
      <Navbar />
      <Hero />
      <Experties />
      <Work />
      <Demo />
      {/* <People /> */}
      <Footer />
    </div>
  );
}

export default App;
