import React from "react";
import { fadeIn, slideIn, staggerContainer } from "../../utils/motion";
import css from "./Hero.module.scss";
import { FiArrowDownCircle } from "react-icons/fi";
import developerDark from "../../images/developer-dark.svg";
import { motion } from "framer-motion";
const Hero = () => {
  return (
    <section className={`paddings ${css.wrapper}`}>
      <motion.div
        variants={staggerContainer}
        initial="hidden"
        whileInView="show"
        viewport={{ once: false, amount: 0.25 }}
        className={`innerWidth ${css.container}`}
      >
        <div className={css.leftElement}>
          <motion.h1
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
              ease: "easeInOut",
              duration: 0.9,
              delay: 0.1,
            }}
            className={css.name}
          >
            Hi, Inzamamul Haque
          </motion.h1>
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
              ease: "easeInOut",
              duration: 0.9,
              delay: 0.2,
            }}
            className={css.secondaryName}
          >
            A Full-Stack Developer
          </motion.p>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
              ease: "easeInOut",
              duration: 0.9,
              delay: 0.3,
            }}
            className={css.downloadBtn}
          >
            <FiArrowDownCircle className={css.downloadIcon}></FiArrowDownCircle>
            <a
              download="InzamamulHaque.pdf"
              href="/files/InzamamulHaque.pdf"
              className={css.text}
              aria-label="Download Resume"
            >
              Download CV
            </a>
          </motion.div>
        </div>
        <motion.div
          initial={{ opacity: 0, y: -180 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ ease: "easeInOut", duration: 0.9, delay: 0.2 }}
          className={css.rightElement}
        >
          <img src={developerDark} alt="Developer" />
        </motion.div>
      </motion.div>
    </section>
  );
};

export default Hero;
