import React from "react";
import { motion } from "framer-motion";
import { comments, sliderSettings } from "../../utils/data";
import css from "./Demo.module.scss";
import {
  fadeIn,
  staggerChildren,
  textVariant,
  textVariant2,
} from "../../utils/motion";
import Slider from "react-slick";

var settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const Portfolio = () => {
  return (
    <section
      variants={staggerChildren}
      initial="hidden"
      whileInView="show"
      viewport={{ once: false, amount: 0.25 }}
      className={`paddings ${css.wrapper}`}
    >
      <a className="anchor" id="portfolio"></a>

      <div className={`innerWidth flexCenter ${css.container}`}>
        <motion.div
          variants={textVariant(0.4)}
          className={`flexCenter ${css.heading}`}
        >
          <div>
            <span className="primaryText">My Latest Works</span>
          </div>
          <span className="secondaryText">Explore More Works</span>
        </motion.div>
        <div className={css.blockRow}>
          <div className={css.block}>
            <Slider {...settings} className={css.slider}>
              <div>
                <img className={css.img} src="./showCase1.png" alt="project" />
              </div>
              <div>
                <img className={css.img} src="./showCase1.png" alt="project" />{" "}
              </div>
              <div>
                <img className={css.img} src="./showCase1.png" alt="project" />{" "}
              </div>
              <div>
                <img className={css.img} src="./showCase1.png" alt="project" />{" "}
              </div>
              <div>
                <img className={css.img} src="./showCase1.png" alt="project" />{" "}
              </div>
              <div>
                <img className={css.img} src="./showCase1.png" alt="project" />{" "}
              </div>
            </Slider>
          </div>

          <div className={css.block}>
            <Slider {...settings} className={css.slider}>
              <div>
                <img className={css.img} src="./showCase1.png" alt="project" />
              </div>
              <div>
                <img className={css.img} src="./showCase1.png" alt="project" />{" "}
              </div>
              <div>
                <img className={css.img} src="./showCase1.png" alt="project" />{" "}
              </div>
              <div>
                <img className={css.img} src="./showCase1.png" alt="project" />{" "}
              </div>
              <div>
                <img className={css.img} src="./showCase1.png" alt="project" />{" "}
              </div>
              <div>
                <img className={css.img} src="./showCase1.png" alt="project" />{" "}
              </div>
            </Slider>
          </div>

          <div className={css.block}>
            <Slider {...settings} className={css.slider}>
              <div>
                <img className={css.img} src="./showCase1.png" alt="project" />
              </div>
              <div>
                <img className={css.img} src="./showCase1.png" alt="project" />{" "}
              </div>
              <div>
                <img className={css.img} src="./showCase1.png" alt="project" />{" "}
              </div>
              <div>
                <img className={css.img} src="./showCase1.png" alt="project" />{" "}
              </div>
              <div>
                <img className={css.img} src="./showCase1.png" alt="project" />{" "}
              </div>
              <div>
                <img className={css.img} src="./showCase1.png" alt="project" />{" "}
              </div>
            </Slider>
          </div>

          <div className={css.block}>
            <Slider {...settings} className={css.slider}>
              <div>
                <img className={css.img} src="./showCase1.png" alt="project" />
              </div>
              <div>
                <img className={css.img} src="./showCase1.png" alt="project" />{" "}
              </div>
              <div>
                <img className={css.img} src="./showCase1.png" alt="project" />{" "}
              </div>
              <div>
                <img className={css.img} src="./showCase1.png" alt="project" />{" "}
              </div>
              <div>
                <img className={css.img} src="./showCase1.png" alt="project" />{" "}
              </div>
              <div>
                <img className={css.img} src="./showCase1.png" alt="project" />{" "}
              </div>
            </Slider>
          </div>

          <div className={css.block}>
            <Slider {...settings} className={css.slider}>
              <div>
                <img className={css.img} src="./showCase1.png" alt="project" />
              </div>
              <div>
                <img className={css.img} src="./showCase1.png" alt="project" />{" "}
              </div>
              <div>
                <img className={css.img} src="./showCase1.png" alt="project" />{" "}
              </div>
              <div>
                <img className={css.img} src="./showCase1.png" alt="project" />{" "}
              </div>
              <div>
                <img className={css.img} src="./showCase1.png" alt="project" />{" "}
              </div>
              <div>
                <img className={css.img} src="./showCase1.png" alt="project" />{" "}
              </div>
            </Slider>
          </div>

          <div className={css.block}>
            <Slider {...settings} className={css.slider}>
              <div>
                <img className={css.img} src="./showCase1.png" alt="project" />
              </div>
              <div>
                <img className={css.img} src="./showCase1.png" alt="project" />{" "}
              </div>
              <div>
                <img className={css.img} src="./showCase1.png" alt="project" />{" "}
              </div>
              <div>
                <img className={css.img} src="./showCase1.png" alt="project" />{" "}
              </div>
              <div>
                <img className={css.img} src="./showCase1.png" alt="project" />{" "}
              </div>
              <div>
                <img className={css.img} src="./showCase1.png" alt="project" />{" "}
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
